.headerServiceAreaWrap {
  position: relative;
  z-index: 100;
  width: 100vw;
  padding: 0 theme('space.4');
  background: theme('colors.white');

  .headerServiceArea {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.headerServiceArea {
  display: flex;
  justify-content: flex-end;
  padding: theme('space.2') 0;
}

.headerService {
  margin: 0 16px;
  line-height: 1;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      background: theme('colors.gray100');
      position: absolute;
      top: 0;
      right: -16px;
    }
  }
}

.emilioHeaderServiceArea {
  .headerService {
    &:first-child {
      margin-left: 0;
    }

    &:after {
      display: none;
    }

    .headerServiceLink {
      border: 0 none;
      font-size: 13px;

      div {
        margin: 0 auto;
      }

      &:hover {
        color: theme('colors.orange');
      }
    }

    img {
      height: 24px;
      object-fit: contain;
      margin: 0 auto theme('space.2');
    }
  }
}

.headerServiceLink {
  text-align: center;
  letter-spacing: 0.3px;
  font-size: 12px;
  display: block;
  padding: 4px 0;
  border-bottom: 2px solid transparent;

  &:hover {
    border-color: theme('colors.primary');
  }
}

.engbersServiceHeaderIconWrap:first-of-type div {
  position: absolute;
  top: 0;
  left: -20px;
}
